// src/utils/analytics.js
export const GA_TRACKING_ID = 'AW-16789693071'

// Initialize Google Analytics
export const initGA = () => {
  // Load the Google Analytics script
  const script1 = document.createElement('script')
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`
  script1.async = true
  document.head.appendChild(script1)

  // Initialize the dataLayer
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', GA_TRACKING_ID)

  // Make gtag available globally
  window.gtag = gtag
}

// Track page views
export const pageView = (url) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

// Track custom events
export const event = ({ action, category, label, value }) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}