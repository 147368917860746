import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';

const TimelineLabel = ({ label, value, verified }) => {
  const formattedValue = value || 'N.A.';

  return (
    <Box 
      sx={{ 
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: '#262262',
          marginRight: '8px',
          marginTop: '6px',
          flexShrink: 0,
        }}
      />
      <Box
        sx={{
          display: 'flex', // Changed to flex instead of grid
          flexWrap: 'wrap', // Allows wrapping only when needed
          width: 'calc(100% - 16px)',
        }}
      >
        <Typography 
          variant="body1" 
          sx={{ 
            color: "#262262",
            fontWeight: 600,
            minWidth: '180px', // Fixed width for label
            marginRight: '8px', // Space between label and value
            flexShrink: 0, // Prevents label from shrinking
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {label}:
          {verified && <DoneIcon sx={{ ml: 1, color: 'green', fontSize: '20px' }} />}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'black',
            fontWeight: 600,
            flex: '1 1 auto', // Allows value to grow and shrink
            minWidth: {
              xs: '200px', // Minimum width before wrapping on mobile
              sm: 'auto', // Let content determine width on larger screens
            },
            wordBreak: 'break-word',
          }}
        >
          {formattedValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default TimelineLabel;