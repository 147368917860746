import React, {useEffect} from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import {initGA} from './utils/analytics';


function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <AppRoutes />
  );
}

export default App;