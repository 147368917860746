import React, { useState } from "react";
import {
  Avatar,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import the styles for PhoneInput
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../../src/assets/iveri.png";
import CustomButton from "../components/Button/CustomButtom";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "2rem",
  },
  submit: {
    margin: "20px",
  },
  heading: {
    fontSize: "34px !important",
    fontWeight: "600 !important",
  },
}));

export default function Register() {
  const classes = useStyles();
  const [creds, setCreds] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    role: "",
  });
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const history=useHistory()



  const handleInputChange = (key, value) => {
    setCreds((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setCreds((prevState) => ({
      ...prevState,
      phone_number: value,
    }));
    setShowOtpInput(true); // Show OTP input when phone number is entered
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (phoneNumber.length < 10) {
      alert("Not a Valid Phone Number");
      return;
    }

    // axios
    //   .post(SERVER_BASE_ADDRESS + "user/register/", creds)
    //   .then((res) => {
    //     localStorage.setItem("user_first_name", creds.first_name);
    //     localStorage.setItem("user_last_name", creds.last_name);
    //     localStorage.setItem("phone_number", creds.phone_number);
    //     localStorage.setItem("email", creds.email);
    //     localStorage.setItem("iveri_id", res.data.iveri_id);

    //     alert("Register Successful");
    //     // setTimeout(() => {
    //     //   window.location = "/login";
    //     // }, 200);
    //     window.location = "/verify-mobile";
    //   })
    //   .catch((error) => {
    //     if (error.response?.status === 400) {
    //       if (error.response.data.phone_number) {
    //         alert("Phone number already exists. Please use a different phone number.");
    //       } else if (error.response.data.email) {
    //         alert("Email already exists. Please use a different Email Id.");
    //       } else {
    //         alert("Error: " + error.message);
    //       }
    //     } else {
    //       alert(error.message);
    //     }
    //   });
     history.push("/verify-mobile", { creds }); 
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <img alt="iVeri logo" src={logo} style={{ width: "150px", height: "80px" }} />
        </div>
        <Typography className={classes.heading}>Sign up</Typography>
        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
          Enter the correct details to sign up your account!
        </Typography>
      </div>

      <Box mt="1rem" className={classes.paper}>
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                First Name
              </Typography>
              <TextField
                autoComplete="fname"
                required
                fullWidth
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px !important",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={(event) => handleInputChange("first_name", event.target.value)}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Last Name
              </Typography>
              <TextField
                required
                fullWidth
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px !important",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={(event) => handleInputChange("last_name", event.target.value)}
                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Email
              </Typography>
              <TextField
                required
                fullWidth
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px !important",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={(event) => handleInputChange("email", event.target.value)}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Phone Number
              </Typography>
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={handlePhoneNumberChange} // Fix this line
                inputStyle={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  border: "none",
                  width: "100%",
                }}
                containerStyle={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Password
              </Typography>
              <TextField
                required
                fullWidth
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px !important",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                type="password"
                onChange={(event) => handleInputChange("password", event.target.value)}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                Sign in as
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  onChange={(event) => handleInputChange("role", event.target.value)}
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px !important",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}>
                  <MenuItem value="1">Candidate</MenuItem>
                  {/* <MenuItem value="2">BGV Agency Admin</MenuItem>
                  <MenuItem value="3">BGV Agency Verifier</MenuItem> */}
                  <MenuItem value="4">Employer</MenuItem>
                  {/* <MenuItem value="8">Recruitment Agency</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container xs={9} mt={4} mx="auto" justifyContent="center">
            <CustomButton onClick={submitHandler}>{"Sign up"}</CustomButton>
            <Grid item>
              <Link to="/">Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
