import React, { useState, useEffect, useRef  } from "react";
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import dayjs from "dayjs";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
  Card,
  Select,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  MenuItem,
  IconButton,
  Container,
  FormControl,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { styled, makeStyles } from "@mui/system";
import ProfileHeader from "../../../components/ProfileHeader";
import DoneIcon from "@mui/icons-material/Done";
import Sidebar from "../../../components/Sidebar";
import CustomAccordian from "../../../components/CustomAccordian";
import { useParams } from "react-router-dom";
import DocumentBox from "../../../components/DocumentBox";
import AddIcon from '@mui/icons-material/Add';

import instance from "../../../globals/axios";

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100px",
  width: "100%",
  cursor: "pointer",
  padding: "8px",
  margin: "16px 0",
};

const fileContainerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #cccccc",
  padding: "8px",
  margin: "8px 0",
};


export default function ExperienceDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  // State variables for form inputs
  const [company, setCompany] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fixedSalaryCurrency, setFixedSalaryCurrency] = useState("");
  const [fixedSalaryAmount, setFixedSalaryAmount] = useState("");
  const [variableSalaryCurrency, setVariableSalaryCurrency] = useState("");
  const [variableSalaryAmount, setVariableSalaryAmount] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [accommodationProvided, setAccommodationProvided] = useState(false);

  const [accommodationFurniture, setAccommodationFurniture] = useState("");
  const [accommodationFamily, setAccommodationFamily] = useState("");
  const [perks, setPerks] = useState("");
  const [designation, setDesignation] = useState("");
  const [reportingTo, setReportingTo] = useState("");
  const [reportee, setReportee] = useState("");
  const [reporteeName, setReporteeName] = useState("");
  const [reporteeTitle, setReporteeTitle] = useState("");
  const [reportePhoneNumber, setReportePhoneNumber] = useState("");
  const [reporteLinkedin, setReporteLinkedin] = useState("");

  const [role, setRole] = useState("");
  const [reportingType, setReportingType] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [officeType, setOfficeType] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [daysNegotiable, setDaysNegotiable] = useState("");
  const [currency, setCurrency] = useState("");
  const [amountInLakhs, setAmountInLakhs] = useState("");
  const [briefRole, setBriefRole] = useState("");
  const [profileTitle, setProfileTitle] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [searchByIveriId, setSearchByIveriId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState('pending');
  const [verifierEmail, setVerifierEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const onDrop = (acceptedFiles) => {
    if (selectedFileName && acceptedFiles[0]) {
      const file = acceptedFiles[0];
      if (file instanceof File) {
        setUploadedFiles(prev => [...prev, {
          file: file,
          fileName: selectedFileName,
          isExisting: false,
          name: file.name,
          type: file.type,
          size: file.size
        }]);
        setSelectedFileName('');
      }
    } else {
      setError('Please select a file name before uploading');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first file selected
    setSelectedFile(file);
    setSelectedFileName(file ? file.name : ""); // Automatically use the file name
  };

  const handleUpload = () => {
    if (selectedFile instanceof File && selectedFileName) {
      setUploadedFiles([...uploadedFiles, {
        file: selectedFile,
        fileName: selectedFileName,
        isExisting: false,
        name: selectedFile.name,
        type: selectedFile.type,
        size: selectedFile.size
      }]);
      setSelectedFile(null);
      setSelectedFileName('');
    } else {
      setError('Please select both a file and a file name');
    }
  };

  const handleRemove = (index) => {
    const fileToRemove = uploadedFiles[index];
    if (fileToRemove.isExisting) {
      // Use the correct endpoint based on your API
      instance.delete(`/experience-details/document/${fileToRemove.id}/`)
        .then(() => {
          const updatedFiles = uploadedFiles.filter((_, idx) => idx !== index);
          setUploadedFiles(updatedFiles);
        })
        .catch((error) => {
          console.error("Error deleting file:", error);
        });
    } else {
      const updatedFiles = uploadedFiles.filter((_, idx) => idx !== index);
      setUploadedFiles(updatedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*",
  });

  const [kraFormData, setKraFormData] = useState([
    {
      kra: "",
      achievement: "",
    },
  ]);
  const [verificationStatus, setVerificationStatus] = useState(null);
  useEffect(() => {
    if (id) {
      instance
        .get(`/experience-details/${id}/`)
        .then((response) => {
          const data = response.data;
          console.log("API Response:", data);

          // Handle currently working first
          const isCurrentlyWorking = data.currently_working === true || 
                                   data.currently_working === "true" || 
                                   data.currently_working === 1;
          
          setCurrentlyWorking(isCurrentlyWorking);
          
          // Set dates
          setStartDate(data.start_date ? dayjs(data.start_date) : null);
          setEndDate(data.end_date && !isCurrentlyWorking ? dayjs(data.end_date) : null);

          // Set reporting fields
          setReportingTo(data.reporting_to || '');
          setReportee(data.reportee || '');
          setReportingType(data.reportingType || '');

          // Set other fields
          setCompany(data.company || '');
          setCompanyDescription(data.company_description || '');
          setWebsite(data.website || '');
          setFixedSalaryCurrency(data.fixed_salary_currency || '');
          setFixedSalaryAmount(data.fixed_salary_amount || '');
          setVariableSalaryCurrency(data.variable_salary_currency || '');
          setVariableSalaryAmount(data.variable_salary_amount || '');
          setAccommodation(data.accommodation || '');
          setAccommodationProvided(Boolean(data.accommodation_provided));
          setAccommodationFurniture(data.accommodation_furniture || '');
          setAccommodationFamily(data.accommodation_family || '');
          setPerks(data.perks || '');
          setDesignation(data.designation || '');
          setReporteeName(data.reporteeName || '');
          setReporteeTitle(data.reporteeTitle || '');
          setReportePhoneNumber(data.reportePhoneNumber || '');
          setReporteLinkedin(data.reporteLinkedin || '');
          setRole(data.role || '');
          setWorkLocation(data.work_location || '');
          setOfficeType(data.office_type || '');
          setNoticePeriod(data.notice_period || '');
          setDaysNegotiable(data.days_negotiable || '');
          setCurrency(data.currency || '');
          setAmountInLakhs(data.expected_salary || '');
          setBriefRole(data.brief_role || '');
          setProfileTitle(data.profile_title || '');
          setVerificationStatus(data.verification_status);
          
          // Handle KRA data
          if (data.kra && Array.isArray(data.kra)) {
            const formattedKraData = data.kra.map(item => ({
              kra: item.kra || '',
              achievement: item.achievement || '',
              id: item.id
            }));
            setKraFormData(formattedKraData);
          }

          // Handle uploaded files
          if (data.uploaded_files && Array.isArray(data.uploaded_files)) {
            const formattedFiles = data.uploaded_files.map(file => ({
              fileName: file.file_name,
              fileUrl: file.file_url,
              id: file.id,
              isExisting: true,
              uploadedAt: file.uploaded_at
            }));
            setUploadedFiles(formattedFiles);
          }

          // If reporting_to exists, fetch the user's name
          if (data.reporting_to) {
            fetchUserData(data.reporting_to);
          }
        })
        .catch((error) => {
          console.error("Error fetching experience details:", error);
        });
    }
  }, [id]);


  const [reportingToName, setReportingToName] = useState('');
  const [reportingToEmail, setReportingToEmail] = useState('');
  const [reportingToId, setReportingToId ] = useState('');

  const fetchUserData = (input) => {
    let endpoint = '';
  
    if (input.includes('@')) {
      // If input contains '@', assume it's an email
      endpoint = `/user/user/email/${encodeURIComponent(input)}/`;
    } else {
      // Otherwise, assume it's an Iveri ID
      endpoint = `/user/user/${input}/`;
    }
  
    instance
      .get(endpoint)
      .then((response) => {
        const userData = response.data.user;
  
        if (userData) {
          const { first_name, last_name, email, iveri_id } = userData;
  
          setReportingToName(`${first_name || ''} ${last_name || ''}`);
          setReportingToEmail(email || '');
          setReportingToId(iveri_id || '');
        } else {
          throw new Error('User data not found');
        }
      })
      .catch((error) => {
        console.error(`Error fetching user data: ${error.message}`);
        // Reset the states if any error occurs
        setReportingToName('');
        setReportingToEmail('');
        setReportingToId('');
      });
  };

  const handleReportingToChange = (event) => {
    const input = event.target.value;
    setReportingTo(input);
    
    if (input.length > 0) {
      fetchUserData(input);
    } else {
      setReportingToName('');
    }
  };
  
  const handleKraChange = (event, index) => {
    const { name, value } = event.target;
    const updatedKraFormData = [...kraFormData];
    updatedKraFormData[index][name] = value;
    setKraFormData(updatedKraFormData);
  };

  const handleAddBoxKra = () => {
    setKraFormData([
      ...kraFormData,
      {
        kra: "",
        achievement: "",
      },
    ]);
  };

  const handleRemoveBoxKra = (index) => {
    const updatedKraFormData = [...kraFormData];
    updatedKraFormData.splice(index, 1);
    setKraFormData(updatedKraFormData);
  };

  const handleSaveClick = () => {
    setLoading(true);
    setError(null);
    
    try {
      const formData = new FormData();
      
      // Basic validation
      if (!company.trim()) {
        throw new Error("Company name is required");
      }
      
      // Format dates
      const formattedStartDate = startDate 
        ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
        : '';
      
      const formattedEndDate = currentlyWorking ? 
        '9999-12-31T23:59:59.999000Z' : 
        endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ') : '';

      // Append all form fields to formData
      formData.append('company', company);
      formData.append('company_description', companyDescription);
      formData.append('website', website);
      formData.append('start_date', formattedStartDate);
      formData.append('end_date', formattedEndDate);
      formData.append('currently_working', currentlyWorking);
      formData.append('fixed_salary_currency', fixedSalaryCurrency);
      formData.append('fixed_salary_amount', fixedSalaryAmount || 0);
      formData.append('variable_salary_currency', variableSalaryCurrency);
      formData.append('variable_salary_amount', variableSalaryAmount || 0);
      formData.append('accommodation', accommodation);
      formData.append('accommodation_furniture', accommodationFurniture);
      formData.append('accommodation_family', accommodationFamily);
      formData.append('perks', perks);
      formData.append('designation', designation);
      formData.append('reporteeName', reporteeName);
      formData.append('reporteeTitle', reporteeTitle);
      formData.append('reportePhoneNumber', reportePhoneNumber);
      formData.append('reporteLinkedin', reporteLinkedin);
      formData.append('role', role);
      formData.append('reportingType', reportingType); 
      formData.append('reporting_to', reportingTo);
      formData.append('reportee', reportee);
      formData.append('work_location', workLocation);
      formData.append('office_type', officeType);
      formData.append('notice_period', noticePeriod);
      formData.append('days_negotiable', daysNegotiable);
      formData.append('currency', currency);
      formData.append('expected_salary', amountInLakhs || 0);
      formData.append('brief_role', briefRole);
      formData.append('profile_title', profileTitle);

      // Handle KRA data
      const kraData = kraFormData.map(item => ({
        kra: item.kra || '',
        achievement: item.achievement || ''
      }));
      formData.append('kra_data', JSON.stringify(kraData));

      // Handle file uploads - Modified to match backend expectations
      const newFiles = uploadedFiles.filter(file => !file.isExisting);
      
      // First save the experience details
      const requestConfig = {
        method: id ? 'PUT' : 'POST',
        url: id ? `/experience-details/${id}/` : '/experience-details/',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      instance(requestConfig)
        .then(response => {
          const experienceId = id || response.data.data.id;
          
          // Handle new files upload
          const newFiles = uploadedFiles.filter(file => !file.isExisting);
          if (newFiles.length > 0) {
            const fileFormData = new FormData();
            fileFormData.append('experience_details_id', experienceId);
            
            // Modified: Use indexed naming for files as expected by backend
            newFiles.forEach((fileObj, index) => {
              if (fileObj.file instanceof File) {
                fileFormData.append(`files_${index}`, fileObj.file);
                fileFormData.append(`file_names_${index}`, fileObj.fileName);
              }
            });

            return instance.post('/upload-experience-details/', fileFormData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            });
          }
          return response;
        })
        .then(response => {
          setSuccess("Data and files saved successfully!");
          setTimeout(() => {
            window.location = "/experience-viewpage";
          }, 200);
        })
        .catch(error => {
          console.error("API Error:", error);
          setError(error.response?.data?.message || "An error occurred while saving");
        })
        .finally(() => {
          setLoading(false);
        });

    } catch (validationError) {
      setError(validationError.message);
      setLoading(false);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
  };

  const handleCurrentlyWorkingChange = (event) => {
    const isChecked = event.target.checked;
    console.log('Setting currently working to:', isChecked);
    setCurrentlyWorking(isChecked);
    
    // Reset end date when checking "Currently Working"
    if (isChecked) {
      setEndDate(null);
    }
  };
  const handleVerifierEmailChange = (e) => {
    setVerifierEmail(e.target.value);
  };

  const handleRequestVerification = async () => {
    if (!verifierEmail) {
      setSnackbar({
        open: true,
        message: 'Please enter verifier email',
        severity: 'error'
      });
      return;
    }

    setLoading(true);
    try {
      const response = await instance.post('experience-verification/request_verification/', {
        experience_id: id,
        verifier_email: verifierEmail
      });

      setSnackbar({
        open: true,
        message: 'Verification request sent successfully',
        severity: 'success'
      });
      setVerifierEmail('');
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to send verification request',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'APPROVED': return 'success';
      case 'REJECTED': return 'error';
      case 'PENDING': return 'warning';
      default: return 'info';
    }
  };

  const renderDocuments = () => (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Uploaded Files
      </Typography>
      {uploadedFiles.map((file, index) => (
        <DocumentBox
          key={index}
          fileName={file.fileName}
          uploadedAt={file.uploadedAt}
          onClick={() => file.fileUrl ? window.open(file.fileUrl) : null}
          onDeleteClick={() => handleRemove(index)}
          showDelete={true}
        />
      ))}
    </Grid>
  );

  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: "30px" }} mb={2}>
        <ProfileHeader showUploadIcon={false} />
        <Grid sx={{ textAlign: "center" }} item xs={12} my={1}>
          <Box display="flex" alignItems="center" pb={1} mt={2} mb={3}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Experience Details
            </Typography>
            <DoneIcon
              sx={{
                color: "white",
                backgroundColor: "#262262",
                fontSize: "15px",
                borderRadius: "100%",
                ml: 1,
              }}
            />
          </Box>
        </Grid>

        <Container my={3}>
          <Grid container mb={6} spacing={2}>
            <CustomAccordian title="Company Details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Company Name
                    {verificationStatus === "APPROVED" && (
                      <DoneIcon
                        sx={{
                          color: "white",
                          backgroundColor: "#262262",
                          fontSize: "15px",
                          borderRadius: "100%",
                          ml: 1,
                        }}
                      />
                    )}
                  </Typography>
                  <TextField
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Company Description
                  </Typography>
                  <TextField
                    value={companyDescription}
                    onChange={(e) => setCompanyDescription(e.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    placeholder="Description"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Website
                  </Typography>
                  <TextField
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Date
                    {verificationStatus === "APPROVED" && (
                      <DoneIcon
                        sx={{
                          color: "white",
                          backgroundColor: "#262262",
                          fontSize: "15px",
                          borderRadius: "100%",
                          ml: 1,
                        }}
                      />
                    )}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        borderRadius: "5px",
                        width: "100%",
                        height: "45px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                          "& input": {
                            padding: "10px 14px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} my={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      disabled={currentlyWorking}
                      sx={{
                        backgroundColor: currentlyWorking ? "rgba(214,214,214, 0.5)" : "rgba(214,214,214, 0.3)",
                        borderRadius: "5px",
                        height: "45px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                          "& input": {
                            padding: "10px 14px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                 
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(currentlyWorking)}
                        onChange={handleCurrentlyWorkingChange}
                      />
                    }
                    label="Currently Working"
                  />
                </Grid>
              </Grid>
            </CustomAccordian>

            <CustomAccordian title="Roles and Responsibilities">
              <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Profile title
                    {verificationStatus === "APPROVED" && (
                      <DoneIcon
                        sx={{
                          color: "white",
                          backgroundColor: "#262262",
                          fontSize: "15px",
                          borderRadius: "100%",
                          ml: 1,
                        }}
                      />
                    )}
                  </Typography>
                  <TextField
                    multiline
                    fullWidth
                    rows={3}
                    value={profileTitle}
                    onChange={(e) => setProfileTitle(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Designation
                    {verificationStatus === "APPROVED" && (
                      <DoneIcon
                        sx={{
                          color: "white",
                          backgroundColor: "#262262",
                          fontSize: "15px",
                          borderRadius: "100%",
                          ml: 1,
                        }}
                      />
                    )}
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    value={designation}
                    placeholder="Designation"
                    onChange={(e) => setDesignation(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                {verificationStatus && (
        <Grid item xs={12}>
          <Alert 
            severity={getStatusColor(verificationStatus)}
            sx={{ mb: 2 }}
          >
            Verification Status: {verificationStatus.replace('_', ' ')}
          </Alert>
        </Grid>
      )}
  {/* <Typography sx={{ fontSize: '15px' }} pb={1}>
    Reporting to
  </Typography> */}
  {/* <TextField
    fullWidth
    variant="outlined"
    value={reportingTo}
    onChange={handleReportingToChange}
    placeholder="Enter Iveri ID or Email ID"
    sx={{
      backgroundColor: 'rgba(214,214,214, 0.3)',
      borderRadius: '5px',
      height: '45px',
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
        '& input': {
          padding: '10px 14px',
        },
      },
    }}
  />

  {reportingToName && (
    <Typography sx={{ fontSize: '14px', mt: 1 }}>
      Name: {reportingToName}
    </Typography>
  )}
  {reportingToEmail && (
    <Typography sx={{ fontSize: '14px', mt: 1 }}>
      Email: {reportingToEmail}
    </Typography>
  )}
  {reportingToId && (
    <Typography sx={{ fontSize: '14px', mt: 1 }}>
      Iveri ID: {reportingToId}
    </Typography>
  )} */}
</Grid>
<Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Request Experience Verification
        </Typography>
      </Grid>
      
      <Grid item xs={12} md={8}>
        <Typography sx={{ fontSize: '15px' }} pb={1}>
          Verifier's Email
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>   
        <TextField
          fullWidth
          variant="outlined"
          value={verifierEmail}
          onChange={handleVerifierEmailChange}
          placeholder="Enter verifier's email address"
          sx={{
            backgroundColor: 'rgba(214,214,214, 0.3)',
            borderRadius: '5px',
            height: '45px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '& input': {
                padding: '10px 14px',
              },
            },
          }}
        />

<Box
          onClick={handleRequestVerification}
          disabled={loading}
          sx={{
            backgroundColor: "rgba(214,214,214, 0.3)",
            borderRadius: "5px",
            padding: "9px",
            "&:hover": {
              backgroundColor: "rgba(214,214,214, 0.5)",
            },
          }}
        >
          <Typography>Verify</Typography>  
        </Box>
        </Box>
      </Grid>
 

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

    <Grid item xs={12}>
        <FormControl fullWidth>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Reporting Type
          </Typography>
          <Box display="flex" alignItems="center">
            <Select
              id="reportingType"
              value={reportingType}
              onChange={(e) => setReportingType(e.target.value)}
              label="Reporting Type"
              variant="outlined"
              sx={{
                flex: 1,
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem value="Direct">Direct</MenuItem>
              <MenuItem value="In Direct">In Direct</MenuItem>
              <MenuItem value="Administrative">Administrative</MenuItem>
              <MenuItem value="Functional">Functional</MenuItem>
              <MenuItem value="Dotted Line">Dotted Line</MenuItem>
              <MenuItem value="Solid Line">Solid Line</MenuItem>
            </Select>

          </Box>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Reporting Manager's Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={reporteeName}
                    onChange={(e) => setReporteeName(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

      <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                  Reporting Manager's  Title
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={reporteeTitle}
                    onChange={(e) => setReporteeTitle(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

      <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                  Reporting Manager's Phone Number
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={reportePhoneNumber}
                    onChange={(e) => setReportePhoneNumber(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

      <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                  Reporting Manager's LinkedIn
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={reporteLinkedin}
                    onChange={(e) => setReporteLinkedin(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Reportee
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={reportee}
                    onChange={(e) => setReportee(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "15px" }} pb={1}>
                      Role
                    </Typography>
                    <Select
                      id="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      label="Role"
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem value="Individual Contributor">Individual Contributor</MenuItem>
                      <MenuItem value="Team">Team</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Work Location
                  </Typography>
                  <TextField
                    fullWidth
                    value={workLocation}
                    onChange={(e) => setWorkLocation(e.target.value)}
                    variant="outlined"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "15px" }} pb={1}>
                      Office Type
                    </Typography>
                    <Select
                      id="office-type"
                      value={officeType}
                      onChange={(e) => setOfficeType(e.target.value)}
                      label="Office Type"
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem value="Corporate Office">Corporate Office</MenuItem>
                      <MenuItem value="Regional Office">Regional Office</MenuItem>
                      <MenuItem value="Plant or Unit">Plant or Unit</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Brief Role
                  </Typography>
                  <TextField
                    multiline
                    fullWidth
                    rows={3}
                    value={briefRole}
                    onChange={(e) => setBriefRole(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Notice period ( in days )
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={noticePeriod}
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (!(charCode >= 48 && charCode <= 57)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setNoticePeriod(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Days Negotiable upto
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={daysNegotiable}
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (!(charCode >= 48 && charCode <= 57)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setDaysNegotiable(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid container justifyContent="center" ml={0.5} spacing={2} my={2}>
                  {kraFormData.map((kraItem, index) => (
                    <Grid key={index} item xs={12} md={6}>
                      <Card sx={{ mx: "auto", p: 2, my: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "15px" }} pb={1}>
                              KRA
                            </Typography>
                            <TextField
                              fullWidth
                              name="kra"
                              value={kraItem.kra}
                              onChange={(e) => handleKraChange(e, index)}
                              sx={{
                                backgroundColor: "rgba(214,214,214, 0.3)",
                                borderRadius: "5px",
                                height: "45px",
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "&:hover fieldset": {
                                    border: "none",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none",
                                  },
                                  "& input": {
                                    padding: "10px 14px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "15px" }} pb={1}>
                              Achievement
                            </Typography>
                            <TextField
                              fullWidth
                              name="achievement"
                              value={kraItem.achievement}
                              onChange={(e) => handleKraChange(e, index)}
                              sx={{
                                backgroundColor: "rgba(214,214,214, 0.3)",
                                borderRadius: "5px",
                                height: "45px",
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "&:hover fieldset": {
                                    border: "none",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "none",
                                  },
                                  "& input": {
                                    padding: "10px 14px",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          {index !== 0 && (
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleRemoveBoxKra(index)}
                                startIcon={<DeleteIcon />}>
                                Remove KRA
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                  <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{ color: "#272260", border: "1px solid #272260" }}
                      onClick={handleAddBoxKra}>
                      + Add Key Responsibility Area (KRA)
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CustomAccordian>

            <CustomAccordian title="Latest Salary and Benefits Details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "17px" }} pb={1}>
                    Fixed Salary
                  </Typography>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        placeholder="Currency"
                        variant="outlined"
                        value={fixedSalaryCurrency}
                        onChange={(e) => setFixedSalaryCurrency(e.target.value)}
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          height: "45px",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}>
                        <MenuItem value="INR">INR</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Amount
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Amount"
                    variant="outlined"
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (!(charCode >= 48 && charCode <= 57)) {
                        event.preventDefault();
                      }
                    }}
                    value={fixedSalaryAmount}
                    onChange={(e) => setFixedSalaryAmount(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "17px" }} pb={1}>
                    Variable Salary
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      variant="outlined"
                      labelId="variable-salary-currency-label"
                      id="variable-salary-currency"
                      label="Currency"
                      value={variableSalaryCurrency}
                      onChange={(e) => setVariableSalaryCurrency(e.target.value)}
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem value="INR">INR</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Amount
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Amount"
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (!(charCode >= 48 && charCode <= 57)) {
                        event.preventDefault();
                      }
                    }}
                    value={variableSalaryAmount}
                    onChange={(e) => setVariableSalaryAmount(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "17px" }} pb={1}>
                    Expected CTC
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="currency"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem value="INR">INR</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} pb={1}>
                    Amount
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Amount"
                    variant="outlined"
                    value={amountInLakhs}
                    onKeyPress={(event) => {
                      const charCode = event.charCode;
                      if (!(charCode >= 48 && charCode <= 57)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setAmountInLakhs(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                    Accommodation
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accommodationProvided}
                        onChange={(e) => setAccommodationProvided(e.target.checked)}
                      />
                    }
                    label="Accommodation Provided"
                  />
                </Grid>

                {accommodationProvided && (
                  <Grid container spacing={2} ml={0} fullWdith>
                    <Grid my={2} item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Typography sx={{ fontSize: "15px" }} pb={1}>
                          Furniture
                        </Typography>
                        <Select
                          fullWidth
                          variant="outlined"
                          id="accommodation-furniture"
                          label="Furniture"
                          value={accommodationFurniture}
                          onChange={(e) => setAccommodationFurniture(e.target.value)}
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            height: "45px",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}>
                          <MenuItem value="Furnished">Furnished</MenuItem>
                          <MenuItem value="Semi-Furnished">Semi-Furnished</MenuItem>
                          <MenuItem value="No Furniture">No Furniture</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Typography sx={{ fontSize: "15px" }} pb={1}>
                          Family
                        </Typography>
                        <Select
                          fullWidth
                          variant="outlined"
                          id="accommodation-family"
                          label="Family"
                          value={accommodationFamily}
                          onChange={(e) => setAccommodationFamily(e.target.value)}
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            height: "45px",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}>
                          <MenuItem value="Bachelor">Bachelor</MenuItem>
                          <MenuItem value="Family">Family</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    placeholder="Perks"
                    variant="outlined"
                    value={perks}
                    onChange={(e) => setPerks(e.target.value)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      height: "45px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </CustomAccordian>

 

            <CustomAccordian title="Document Upload">
              <Grid container spacing={2}>
                <Grid container spacing={2} item ml={1}>
                  <Grid my={2} xs={12}>
                    <Typography variant="h6" gutterBottom>
                      File Upload Section:
                    </Typography>
                  </Grid>

                  <Grid xs={12} fullWdith mb={2}>
                    <FormControl fullWidth>
                      <Typography sx={{ fontSize: "15px" }} pb={1}>
                        Select File Name
                      </Typography>
                      <Select
                        value={selectedFileName}
                        label="Select File Name"
                        onChange={(e) => setSelectedFileName(e.target.value)}
                        sx={{
                          backgroundColor: "rgba(214,214,214, 0.3)",
                          height: "45px",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}>
                        <MenuItem value="Offer Letter with CTC Breakup">
                          Offer Letter with CTC Breakup
                        </MenuItem>
                        <MenuItem value="Appointment Letter with CTC breakup">
                          Appointment Letter with CTC breakup
                        </MenuItem>
                        <MenuItem value="Latest 3 Months Salary Slips">
                          Latest 3 Months Salary Slips
                        </MenuItem>
                        <MenuItem value="Increment Letter">Increment Letter</MenuItem>
                        <MenuItem value="Promotion Letter">Promotion Letter</MenuItem>
                        <MenuItem value="Resignation Letter">Resignation Letter</MenuItem>
                        <MenuItem value="Resignation Acceptance Letter">
                          Resignation Acceptance Letter
                        </MenuItem>
                        <MenuItem value="Reliving Letter">Reliving Letter</MenuItem>
                        <MenuItem value="Experience Letter">Experience Letter</MenuItem>
                        <MenuItem value="Achievements Rewards Certification Document">
                          Achievements / Rewards Certification Document
                        </MenuItem>
                        <MenuItem value="Others">Others </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Drag & drop files here, or click to select files</p>
                  </div>

                  {selectedFile && (
                    <Typography variant="subtitle1" gutterBottom>
                      Selected File: {selectedFile.name}
                    </Typography>
                  )}

                  <Grid mt={1} mb={2} xs={8} mx="auto">
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ backgroundColor: "#272260", color: "white" }}
                      onClick={handleUpload}>
                      + Add File
                    </Button>
                  </Grid>

                  {uploadedFiles.length > 0 && (
  <>
   <Grid item xs={12}>
    <Typography variant="h5" gutterBottom>
      Uploaded Files
    </Typography>
    {renderDocuments()}
            </Grid>
  </>
)}
                </Grid>
              </Grid>
            </CustomAccordian>

            <Grid item container>
              <Grid mx="auto" my={2} px={1} item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "#272260",
                  }}
                  onClick={handleSaveClick}>
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
