import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  CircularProgress, 
  Box,
  Alert 
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import instance from "../../globals/axios"

const VerificationResponse = () => {
  const location = useLocation();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    // Get params from URL using URLSearchParams
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const action = params.get('action');

    if (!token || !action) {
      setStatus('error');
      setMessage('Invalid verification link');
      return;
    }

    const verifyExperience = async () => {
      try {
        const response = await instance.get(`experience-verification/verify/`, {
          params: { token, action }
        });
        setStatus('success');
        setMessage(response.data.message);
      } catch (error) {
        setStatus('error');
        setMessage(error.response?.data?.error || 'Verification failed');
      }
    };

    verifyExperience();
  }, [location]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        {status === 'loading' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <CircularProgress />
            <Typography>Processing verification...</Typography>
          </Box>
        )}

        {status === 'success' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h5">Verification Successful</Typography>
            <Alert severity="success">{message}</Alert>
          </Box>
        )}

        {status === 'error' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <ErrorIcon color="error" sx={{ fontSize: 60 }} />
            <Typography variant="h5">Verification Failed</Typography>
            <Alert severity="error">{message}</Alert>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default VerificationResponse;