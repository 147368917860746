// src/data/documentOptions.js
export const documentOptions = [
  { label: "Offer Letter with CTC breakup", value: "offer_letter" },
  { label: "Appointment Letter with CTC breakup", value: "appointment_letter" },
  { label: "Latest 3 Months Salary Slips", value: "salary_slips" },
  { label: "Increment Letter", value: "increment_letter" },
  { label: "Promotion Letter", value: "promotion_letter" },
  { label: "Resignation Letter", value: "resignation_letter" },
  { label: "Resignation Acceptance Letter", value: "resignation_acceptance_letter" },
  { label: "Relieving Letter", value: "relieving_letter" },
  { label: "Experience Letter", value: "experience_letter" },
  { label: "Achievements / Rewards Certification", value: "achievements_certification" },
  { label: "Other", value: "other_document" },
];

export const hobbiesArray = [
  "Reading",
  "Painting",
  "Cooking",
  "Photography",
  "Hiking",
  "Gardening",
  "Playing Guitar",
  "Swimming",
  "Dancing",
  "Traveling",
  "Yoga",
  "Fishing",
  "Singing",
  "Chess",
  "Cycling",
  "Writing",
  "Knitting",
  "Watching Movies",
  "Playing Tennis",
  "Collecting Stamps",
  "Mountain Climbing",
  "Skydiving",
  "Scuba Diving",
  "Bird Watching",
  "Pottery",
  "Woodworking",
  "Meditation",
  "Volunteering",
  "Archery",
  "Cooking",
  "Skiing",
  "Baking",
  "Camping",
  "Running",
  "Drawing",
  "Puzzle Solving",
  "Sculpting",
  "Golfing",
  "Surfing",
  "Sewing",
  "Horseback Riding",
  "Ice Skating",
  "Rowing",
  "Bowling",
  "Billiards",
  "Model Building",
  "Calligraphy",
  "Astronomy",
  "Collecting Coins",
];


export  const engineeringSkills = [
  "Algorithms",
  "Analytical Skills",
  "Big Data",
  "Calculating",
  "Compiling Statistics",
  "Data Analytics",
  "Data Mining",
  "Database Design",
  "Database Management",
  "Documentation",
  "Modeling",
  "Modification",
  "Needs Analysis",
  "Quantitative Research",
  "Quantitative Reports",
  "Statistical Analysis",
  "Applications",
  "Certifications",
  "Coding",
  "Computing",
  "Configuration",
  "Customer Support",
  "Debugging",
  "Design",
  "Development",
  "Hardware",
  "Implementation",
  "Information Technology",
  "Infrastructure",
  "Languages",
  "Maintenance",
  "Network Architecture",
  "Network Security",
  "Networking",
  "New Technologies",
  "Operating Systems",
  "Programming",
  "Restoration",
  "Security",
  "Servers",
  "Software",
  "Solution Delivery",
  "Storage",
  "Structures",
  "Systems Analysis",
  "Technical Support",
  "Technology",
  "Testing",
  "Tools",
  "Training",
  "Troubleshooting",
  "Usability",
  "Benchmarking",
  "Budget Planning",
  "Engineering",
  "Fabrication",
  "Following Specifications",
  "Operations",
  "Performance Review",
  "Project Planning",
  "Quality Assurance",
  "Quality Control",
  "Scheduling",
  "Task Delegation",
  "Task Management",
  "Blogging",
  "Digital Photography",
  "Digital Media",
  "Facebook",
  "Instagram",
  "Networking",
  "Pinterest",
  "SEO",
  "Social Media Platforms",
  "Twitter",
  "Web Analytics",
  "Client Relations",
  "Email",
  "Requirements Gathering",
  "Research",
  "Subject Matter Experts (SMEs)",
  "Technical Documentation",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Civil Engineering",
  "Software Engineering",
  "Chemical Engineering",
  "Industrial Engineering",
  "Aerospace Engineering",
  "Biomedical Engineering",
  "Materials Engineering",
  "Environmental Engineering",
  "Structural Engineering",
  "Systems Engineering",
  "Robotics",
  "CAD/CAM Design",
  "Thermodynamics",
  "Fluid Mechanics",
  "Control Systems",
  "Machine Learning",
  "Data Analysis",
  "Project Management",
  "Technical Writing",
  "Problem Solving",
  "Mathematics",
  "Physics",
  "CAD Software (e.g., AutoCAD)",
  "Finite Element Analysis (FEA)",
  "Electronics",
  "Instrumentation",
  "Quality Assurance",
  "Teamwork",
  "Communication Skills",
  "Innovation",
  "Research and Development",
  "Programming (e.g., C++, Python)",
  "Digital Signal Processing",
  "Optimization",
  "CAD/CAM Software",
  "Manufacturing Processes",
  "Risk Assessment",
  "Safety Engineering",
  "Systems Integration",
  "Reliability Engineering",
  "Cost Analysis",
  "Energy Efficiency",
  "Ethical Decision-Making",
  "Leadership",
  "Time Management",
  "Critical Thinking",
  "Creativity",
  "Troubleshooting",
  "Continuous Learning",
  "Six Sigma",
  "Lean Manufacturing",
  "Failure Mode and Effects Analysis (FMEA)",
  "Root Cause Analysis",
  "Computer-Aided Engineering (CAE)",
  "Process Improvement",
  "Simulation and Modeling",
  "CAD Drafting",
  "Product Design",
  "Geotechnical Engineering",
  "Renewable Energy",
  "Hydrology",
  "3D Printing",
  "CAD/CAM Programming",
  "Statistical Analysis",
  "Supply Chain Management",
  "Technical Presentations",
  "Finite Element Analysis (FEA)",
  "Design for Manufacturing (DFM)",
  "Environmental Impact Assessment",
  "Occupational Health and Safety",
  "Patent Analysis",
  "Statistical Process Control (SPC)",
  "Geographic Information Systems (GIS)",
  "Circuit Design",
  "Embedded Systems",
  "Automation",
  "Machining",
  "Welding",
  "Chemistry",
  "Laboratory Techniques",
  "Computer Science",
  "Internet of Things (IoT)",
  "Agile Methodologies",
  "CAD/CAM Machining",
  "Instrument Calibration",
  "JavaScript",
  "Java",
  "C#",
  "Ruby",
  "PHP",
  "SQL",
  "HTML/CSS",
  "Python",
  "C++",
  "MATLAB",
  "R",
  "Version Control (e.g., Git)",
  "Web Development",
  "Database Design",
  "Algorithm Development",
  "Object-Oriented Programming (OOP)",
  "Software Testing",
];

export  const languageOptions = [
  "English",
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Manipuri",
  "Marathi",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu",
  "Others",
];
